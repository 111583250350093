import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { axios } from '@oncore/shared';
import { Permission } from 'src/global/types';

export type UserContext = {
  context: 'user';
  displayName: string;
  permissions: Permission[];
};

type LoadingContext = {
  context: 'loading';
};

type ErrorContext = {
  context: 'error';
  error: Error;
};

type OncoreFlexBranding = {
  smallLogoUrl?: string | null;
  largeLogoUrl?: string | null;
};

type UserState = {
  context: UserContext | LoadingContext | ErrorContext;
  token: string | null;
  isMenuOpen: boolean;
  branding: OncoreFlexBranding;
};

const initialState: UserState = {
  context: { context: 'loading' },
  token: null,
  isMenuOpen: false,
  branding: {},
};

const TOKEN_STORE_KEY = 'token';

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      axios.defaults.headers.common.Authorization = `Bearer ${action.payload}`;
      localStorage.setItem(TOKEN_STORE_KEY, action.payload);
      state.token = action.payload;
    },

    setContext: (state, action: PayloadAction<UserContext | ErrorContext>) => {
      state.context = action.payload;
    },

    logout: () => {
      localStorage.removeItem(TOKEN_STORE_KEY);
      delete axios.defaults.headers.common.Authorization;
      return initialState;
    },

    setMenuOpen: (state, action: PayloadAction<boolean>) => {
      state.isMenuOpen = action.payload;
    },

  }
});

export const { setToken, setContext, logout, setMenuOpen } = appSlice.actions;

export default appSlice.reducer;
