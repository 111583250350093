import { Box } from '@mui/material';
import defaultSmallLogo from 'src/assets/img/monogram.svg';
import defaultLargeLogo from 'src/assets/img/navigation-logo-bos.svg';
import { useSelector } from 'src/hooks';
import OncoreFlexMenuRow from './OncoreFlexMenuRow';

const OncoreFlexMenuLogo = () => {
  const { branding } = useSelector((s) => s.app);

  const {
    smallLogoUrl,
    largeLogoUrl
  } = branding || { };

  const smallLogo = (
    <img
      alt=""
      src={smallLogoUrl ?? defaultSmallLogo}
      style={{
        maxWidth: '42px',
        maxHeight: '42px'
      }}
    />
  );

  const largeLogo = (
    <Box paddingX={2} paddingY={5} marginLeft="auto">
      <img
        alt=""
        src={largeLogoUrl ?? defaultLargeLogo}
      />
    </Box>
  );

  return (
    <OncoreFlexMenuRow icon={smallLogo}>
      {largeLogo}
    </OncoreFlexMenuRow>
  );
};

export default OncoreFlexMenuLogo;
