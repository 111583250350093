type Node<T,> = {
  [P in keyof T]: (T[P] extends string
    ? string
    : T[P] extends ((...args: string[]) => string)
      ? T[P]
      : Node<T[P]>)
};

const Links = {
  home: '/',
  tenants: '/tenants',
  tenant: {
    new: '/tenants/new',
    newAgency: '/tenants/agency/new',
    newEnterprise: '/tenants/enterprise/new',
    view: (tenantID: string) => `/tenants/${tenantID}`,
    details: (tenantID: string) => `/tenants/${tenantID}/details`,
    settings: (tenantID: string) => `/tenants/${tenantID}/settings`,
    users: (tenantID: string) => `/tenants/${tenantID}/users`,
    user: (tenantID: string, userID: string) => `/tenants/${tenantID}/users/${userID}`,
    groups: (tenantID: string) => `/tenants/${tenantID}/groups`,
    group: (tenantID: string, groupID: string) => `/tenants/${tenantID}/groups/${groupID}`,
    positions: (tenantID: string) => `/tenants/${tenantID}/positions`,
    placements: (tenantID: string) => `/tenants/${tenantID}/placements`,
    contracts: (tenantID: string) => `/tenants/${tenantID}/contracts`,
    timesheets: (tenantID: string) => `/tenants/${tenantID}/timesheets`,
  },
  users: '/users',
  user: {
    new: '/users/new',
    view: (userID: string) => `/users/${userID}`,
    details: (userID: string) => `/users/${userID}/details`,
    invoices: (userID: string) => `/users/${userID}/invoices`
  }
};

const links: Node<typeof Links> = Links;

export default links;
