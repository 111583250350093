import type { FC } from 'react';
import { GlobalStyles as MuiGlobalStyles } from '@mui/material';


const GlobalStyles: FC = () => {
  return (
    <MuiGlobalStyles
      styles={{
        '*': {
          boxSizing: 'border-box',
          margin: 0,
          padding: 0,
        },
        html: {
          WebkitFontSmoothing: 'antialiased',
          MozOsxFontSmoothing: 'grayscale',
          height: '100%',
          width: '100%'
        },
        body: {
          height: '100%',
          width: '100%'
        },
        '#root': {
          height: '100%',
          width: '100%'
        }
      }}
    />
  );
};

export default GlobalStyles;
