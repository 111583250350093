import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import {
  logout,
  setToken,
  setContext
} from 'src/store/appSlice';
import { useDispatch } from './useStore';
import {
  AnalyticsEvents,
  useSetAnalyticsUserID,
  useSetAnalyticsUserProperties,
  useAnalyticsUpdater
} from '@oncore/shared';

export const useAuthInit = () => {
  const auth0 = useAuth0();
  const location = useLocation();
  const dispatch = useDispatch();

  const sendAnalyticsLogin = useAnalyticsUpdater(AnalyticsEvents.LOGIN);
  const setAnalyticsUserID = useSetAnalyticsUserID();
  const setAnalyticsUserProperties = useSetAnalyticsUserProperties();

  useEffect(() => {
    if (!auth0.isLoading && !auth0.isAuthenticated) {
      auth0.loginWithRedirect({
        appState: {
          returnTo: location.pathname
        }
      });
    }
    if (auth0.isAuthenticated) {
      auth0.getAccessTokenSilently()
        .then((token) => dispatch(setToken(token)));
    }
    if (auth0.error) {
      dispatch(setContext({
        context: 'error',
        error: auth0.error,
      }));
    }
    if (auth0.user) {
      dispatch(setContext({
        context: 'user',
        displayName: auth0.user!.name || '',
        permissions: [],
      }));
      if (auth0.user.sub) {
        setAnalyticsUserID(auth0.user.sub);
      } else {
      }
      setAnalyticsUserProperties({
        tenant: 'none',
        context: 'bos',
        features: [],
        permissions: []
      });

      sendAnalyticsLogin();
    }
  }, [auth0]);

};

export const useAuthLogout = () => {
  const auth0 = useAuth0();
  const dispatch = useDispatch();

  const sendAnalyticsLogout = useAnalyticsUpdater(AnalyticsEvents.LOGOUT);

  return () => {
    sendAnalyticsLogout();
    auth0.logout({ logoutParams: { returnTo: window.location.origin } });
    dispatch(logout());
  };
};
