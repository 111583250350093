import { Outlet } from 'react-router-dom';
import { LoadingScreen } from '@oncore/shared';
import { useAuthInit, useSelector } from 'src/hooks';

export type Props = {
  children?: never;
};

export const AuthGuard = (_: Props) => {

  const { context } = useSelector((s) => s.app);

  useAuthInit();

  return context.context === 'loading'
    ? <LoadingScreen />
    : <Outlet />;
};

export default AuthGuard;
