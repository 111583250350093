import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  alpha,
  Typography,
  Theme
} from '@mui/material';
import { useDispatch } from 'src/hooks';
import {
  isMenuItemChildren,
  isMenuItemClick,
  isMenuItemHref,
  isSubMenuItem,
  isTopMenuItem,
  MenuItem,
  MenuItemChildren,
  SubMenuItem,
  TopMenuItem
} from './menu';
import OncoreFlexMenuRow from './OncoreFlexMenuRow';
import { setMenuOpen } from 'src/store/appSlice';
import { TrackedButton } from '@oncore/shared';

const sxNormal = (theme: Theme, active?: boolean) => ({
  borderRadius: 0,
  padding: 0,
  height: '42px',
  justifyContent: 'flex-start',
  textTransform: 'none',
  letterSpacing: 0,
  width: '100%',
  color: theme.palette.primary.contrastText,
  // fontWeight: theme.typography.fontWeightRegular,
  '&.depth-0': {
    fontWeight: theme.typography.fontWeightMedium
  },
  backgroundColor: active ? alpha(theme.palette.primary.contrastText, 0.23) : 'inherit'
});

export type Props = {
  goSub: (item: MenuItem & MenuItemChildren) => void;
  item: TopMenuItem | SubMenuItem;
  depth?: number;
  children?: never;
};

const OncoreFlexMenuMobileItem: React.FC<Props> = (props) => {
  const {
    item,
    depth = 0,
    goSub,
  } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const permissions = isMenuItemPermissions(item) ? item.permissions : [];

  const isSuitableContext = true;

  const isGranted = true;// useAccess(permissions);

  const sub = isSubMenuItem(item);
  const Icon = (isTopMenuItem(item) ? (<item.icon {...item.iconProps} />) : null);
  const title = typeof item.title === 'function' ? item.title() : item.title;

  const typo = (
    <Box ml={2 + 2 * depth} mr={2} alignSelf="center">
      <Typography variant={sub ? 'body2' : 'h5'}>
        {title}
      </Typography>
    </Box>
  );

  const component = isMenuItemHref(item) ? (
    <TrackedButton
      trackedName={title}
      sx={(theme) => sxNormal(theme, location.pathname === item.href)}
      href={item.href}
      onClick={(e) => {
        e.preventDefault();
        dispatch(setMenuOpen(false));
        navigate(item.href);
      }}
    >
      {typo}
    </TrackedButton>
  ) : isMenuItemClick(item) ? (
    <TrackedButton
      trackedName={title}
      onClick={item.onClick}
      sx={sxNormal}
    >
      {typo}
    </TrackedButton>
  ) : isMenuItemChildren(item) ? (
    <TrackedButton
      trackedName={title}
      onClick={() => goSub(item)}
      sx={sxNormal}
    >
      {typo}
    </TrackedButton>
  ) : undefined;

  const content = (
    <>
      {component}
    </>
  );

  const ren = (
    <OncoreFlexMenuRow icon={Icon}>
      {content}
    </OncoreFlexMenuRow>
  );

  return isGranted && isSuitableContext ? ren : (<></>);
};

export default OncoreFlexMenuMobileItem;
