import { useRef, useState } from 'react';
import {
  Box,
  ButtonBase,
  Divider,
  Menu,
  MenuItem
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  StyledAvatar,
  TrackedMenuItem,
  useEnvironment,
  useSnackbar
} from '@oncore/shared';
import { Person } from '@mui/icons-material';
import { useSelector, useAuthLogout } from 'src/hooks';

const AccountBox = styled(Box)(({ theme }) => ({
  ...theme.typography.h5,
  height: '64px',
  margin: theme.spacing(0, 2),
  padding: theme.spacing(0, 2),
  alignSelf: 'stretch',
  '& span': {
    textAlign: 'left',
    lineHeight: '16px',
    marginLeft: theme.spacing(2),
    '& small': {
      fontWeight: 400,
      opacity: 0.5
    }
  }
}));

const OncoreFlexHeaderAccount = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState<boolean>(false);

  const context = useSelector((s) => s.app.context);
  const logout = useAuthLogout();

  const {
    version
  } = useEnvironment();

  const openMenu = (): void => {
    setOpen(true);
  };
  const closeMenu = (): void => {
    setOpen(false); 
  };

  const doLogout = async (): Promise<void> => {
    try {
      closeMenu();
      logout();
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };

  const menu = (
    <Menu
      onClose={closeMenu}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      keepMounted
      anchorEl={ref.current}
      open={open}
    >
      <MenuItem disabled>
        {version}
      </MenuItem>
      <Divider />
      <TrackedMenuItem onClick={doLogout} trackedName="Logout">
        Logout
      </TrackedMenuItem>
    </Menu>
  );

  return (
    <>
      <Box ref={ref} sx={{
        display: 'flex',
        alignSelf: 'stretch',
        button: {
          alignSelf: 'stretch',
        }
      }}>
        <AccountBox
          component={ButtonBase}
          onClick={openMenu}
          mx={2}
        >
          <StyledAvatar>
            <Person />
          </StyledAvatar>
          <span>
            {context.context === 'user' && context.displayName}
          </span>
        </AccountBox>
      </Box>
      {menu}
    </>
  );
};

export default OncoreFlexHeaderAccount;