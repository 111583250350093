import { Provider } from 'react-redux';
import { RelayEnvironmentProvider } from 'relay-hooks';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { useEnvironment, SnackbarProvider } from '@oncore/shared';
import environment from './environment';
import { store } from './store/store';
import OncoreFlexRouter from './components/OncoreFlexRouter';
import GlobalStyles from './components/GlobalStyles';

export type Props = {
  children?: never;
};

export const App = (_: Props) => {
  const {
    auth
  } = useEnvironment();
  const {
    domain,
    clientID,
    audience
  } = auth || { };

  const onRedirectCallback = (appState: AppState | undefined) => {
    if (appState?.returnTo) {
      document.location.href = appState.returnTo;
    }
  };

  return (
    <Auth0Provider
      domain={domain || ''}
      clientId={clientID || ''}
      authorizationParams={{
        audience: audience || '',
        scope: 'profile bos',
        redirect_uri: window.location.origin
      }}
      onRedirectCallback={onRedirectCallback}
    >
      <Provider store={store}>
        <SnackbarProvider>
          <RelayEnvironmentProvider environment={environment}>
            <GlobalStyles />
            <OncoreFlexRouter />
          </RelayEnvironmentProvider>
        </SnackbarProvider>
      </Provider>
    </Auth0Provider>
  );
};

export default App;
