import { useTheme } from '@mui/material';
import OncoreFlexMenuRow from './OncoreFlexMenuRow';

const OncoreFlexMenuDivider = () => {
  const theme = useTheme();

  return (<OncoreFlexMenuRow height={theme.spacing(2)} />);
};

export default OncoreFlexMenuDivider;
