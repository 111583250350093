import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  alpha,
  Typography
} from '@mui/material';
import { useDispatch } from 'src/hooks';
import { setMenuOpen } from 'src/store/appSlice';
import {
  isDivMenuItem,
  isMenuItemChildren,
  isMenuItemClick,
  isMenuItemHref,
  isSubMenuItem,
  isTopMenuItem,
  SubMenuItem,
  TopMenuItem
} from './menu';
import OncoreFlexMenuRow from './OncoreFlexMenuRow';
import OncoreFlexMenuDivider from './OncoreFlexMenuDivider';
import { TrackedButton } from '@oncore/shared';


export type Props = {
  item: TopMenuItem | SubMenuItem;
  depth: number;
  children?: never;
};

const OncoreFlexMenuDesktopItem = (props: Props) => {
  const {
    item,
    depth,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const isGranted = true;

  const sub = isSubMenuItem(item);
  const Icon = (isTopMenuItem(item) ? (<item.icon {...item.iconProps} />) : null);
  const title = typeof item.title === 'function' ? item.title() : item.title;

  const typo = (
    <Box ml={2 + 2 * depth} mr={2} alignSelf="center">
      <Typography variant={sub ? 'body2' : 'h5'}>
        {title}
      </Typography>
    </Box>
  );

  const component = isMenuItemHref(item) ? (
    <TrackedButton
      trackedName={title}
      sx={(theme) => ({
        borderRadius: 0,
        padding: 0,
        height: '42px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        color: theme.palette.primary.contrastText,
        fontWeight: theme.typography.fontWeightRegular,
        '&.depth-0': {
          fontWeight: theme.typography.fontWeightMedium
        },
        backgroundColor: location.pathname === item.href ? alpha(theme.palette.primary.contrastText, 0.23) : undefined,
        '&:hover': location.pathname === item.href ? {
          backgroundColor: alpha(theme.palette.primary.contrastText, 0.23)
        } : undefined
      })}
      href={item.href}
      onClick={(e) => {
        e.preventDefault();
        dispatch(setMenuOpen(false));
        navigate(item.href);
      }}
    >
      {typo}
    </TrackedButton>
  ) : isMenuItemClick(item) ? (
    <TrackedButton
      trackedName={item.title.toString()}
      onClick={item.onClick}
      sx={(theme) => ({
        borderRadius: 0,
        padding: 0,
        height: '42px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        color: theme.palette.primary.contrastText,
        fontWeight: theme.typography.fontWeightRegular,
        '&.depth-0': {
          fontWeight: theme.typography.fontWeightMedium
        }
      })}
    >
      {typo}
    </TrackedButton>
  ) : typo;

  const ren = (
    <li>
      <OncoreFlexMenuRow icon={Icon} height={isMenuItemChildren(item) ? '42px' : undefined}>
        {component}
      </OncoreFlexMenuRow>
      {isMenuItemChildren(item) && (
        <ul>
          {item.children.map((child) => isDivMenuItem(child)
            ? <OncoreFlexMenuDivider key={child.id} />
            : <OncoreFlexMenuDesktopItem key={child.id} item={child} depth={depth + 1} />)}
        </ul>
      )}
    </li>
  );

  return isGranted ? ren : (<></>);
};

OncoreFlexMenuDesktopItem.defaultProps = {
  depth: 0
};

export default OncoreFlexMenuDesktopItem;
