import type { FC, ReactNode } from 'react';
import {
  Box,
  Toolbar,
  useTheme
} from '@mui/material';
import styled from 'styled-components';
import { Outlet } from 'react-router-dom';
import {
  OncoreFlexHeader,
  HamburgerButton,
  ErrorBoundary,
} from '@oncore/shared';
import { useDispatch, useSelector } from 'src/hooks';
import { setMenuOpen } from 'src/store/appSlice';
import OncoreFlexHeaderAccount from './OncoreFlexHeaderAccount';
import OncoreFlexMenu from './OncoreFlexMenu';

type Props = {
  children?: ReactNode;
};

const Root = styled.div`
  display: flex;
  height: 100%;
  overflow: hidden;
  width: 100%;
`;
const MobileMenuButton = styled.div`
  width: 70px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  @media (min-width: 1200px) {
    display: none;
  }
`;
const Content = styled.div<{ $backgroundColor: string }>`
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-top: 56px;
  background-color: ${(p) => p.$backgroundColor};
  @media (min-width: 1200px) {
    padding-left: 256px;
    padding-top: 64px;
  }
`;

const OncoreFlexLayout: FC<Props> = () => {
  const dispatch = useDispatch();
  const { isMenuOpen } = useSelector((s) => s.app);
  const theme = useTheme();

  return (
    <Root>
      <OncoreFlexHeader>
        <Toolbar disableGutters>
          <MobileMenuButton>
            <HamburgerButton
              trackedName="Open side menu"
              open={isMenuOpen}
              onClick={() => dispatch(setMenuOpen(!isMenuOpen))}
            />
          </MobileMenuButton>
          <Box mx={2} flexGrow={1} />
          <Box sx={{ display: { sx: 'none', lg: 'flex' } }}>
            <OncoreFlexHeaderAccount />
          </Box>
        </Toolbar>
      </OncoreFlexHeader>
      <OncoreFlexMenu />
      <Content $backgroundColor={theme.palette.background.default}>
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </Content>
    </Root>
  );
};

export default OncoreFlexLayout;
