import React from 'react';
import {
  Box,
  Drawer,
  useMediaQuery
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useSelector } from 'src/hooks';
import { isDivMenuItem, TopMenuItems } from './menu';
import OncoreFlexMenuLogo from './OncoreFlexMenuLogo';
import OncoreFlexMenuDivider from './OncoreFlexMenuDivider';
import OncoreFlexMenuDesktopItem from './OncoreFlexMenuDesktopItem';
import OncoreFlexMenuFill from './OncoreFlexMenuFill';

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiPaper-root': {
    border: 'none',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  }
}));

export type Props = {
  items: TopMenuItems;
  open: boolean;
  children?: never;
};

const MenuDesktop: React.FC<Props> = ({ items, open }) => {
  return (<Box displayPrint="none">
    <StyledDrawer anchor="left" variant="persistent" open={open}>
      <OncoreFlexMenuLogo />
      <ul>
        {items.map((item) => isDivMenuItem(item)
          ? <OncoreFlexMenuDivider key={item.id} />
          : <OncoreFlexMenuDesktopItem key={item.id} item={item} />)}
      </ul>
      <OncoreFlexMenuFill />
    </StyledDrawer>
  </Box>);
};


const MenuContainer = (props: Omit<Props, 'open'>) => {
  const { isMenuOpen } = useSelector((s) => s.app);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  return <MenuDesktop {...props} open={isDesktop || isMenuOpen} />;
};

export default MenuContainer;
