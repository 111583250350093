import {
  Dashboard,
  Business,
  Person,
  SvgIconComponent
} from '@mui/icons-material';
import { Icon } from 'react-feather';
import links from './links';
import { Permission } from 'src/global/types';
import { OverridableComponent, OverrideProps } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material';

export const MENU_ITEM_TYPE_TOP = 'top';
export const MENU_ITEM_TYPE_SUB = 'sub';
export const MENU_ITEM_TYPE_DIV = 'div';

export type MenuItemHref = {
  href: string;
};

export type MenuItemClick = {
  onClick: () => void;
};

export type MenuItemChildren = {
  children: SubMenuItems;
};

export type MenuItemVisible = {
  visible?: boolean | (() => boolean);
};

export type MenuItemPermissions = {
  permissions: Permission[];
};

export type MenuItemVisibility = MenuItemVisible | MenuItemPermissions;

export function isMenuItemVisible(item: MenuItemVisibility): item is MenuItemVisible {
  return Object.keys(item).includes('visible');
}

export function isMenuItemPermissions(item: MenuItemVisibility): item is MenuItemPermissions {
  return Object.keys(item).includes('permissions');
}

export type MenuNode = {
  id: string;
  enabled?: boolean | (() => boolean);
};

export type MenuItemAction = MenuItemHref | MenuItemClick | MenuItemChildren;

export type MenuItem = MenuNode
& (MenuItemAction)
& (MenuItemVisible | MenuItemPermissions)
& {
  title: string | (() => string);
};

export function isMenuItemHref(item: MenuItemAction): item is MenuItemHref {
  return Object.keys(item).includes('href');
}

export function isMenuItemClick(item: MenuItemAction): item is MenuItemClick {
  return Object.keys(item).includes('onClick');
}

export type TopMenuItemAction =
  | MenuItemHref
  | MenuItemClick
  | MenuItemChildren;

export type TopMenuItem = MenuItem & TopMenuItemAction & {
  type: typeof MENU_ITEM_TYPE_TOP;
  icon: OverridableComponent<SvgIconTypeMap<Record<string, any>, 'svg'>> | Icon;
  iconProps?: OverrideProps<SvgIconTypeMap, SvgIconComponent>;
};

export type SubMenuItemAction =
  | MenuItemHref
  | MenuItemClick
  | MenuItemChildren;

export type SubMenuItem = MenuItem & SubMenuItemAction & {
  type: typeof MENU_ITEM_TYPE_SUB;
};

export type DivMenuItem = MenuNode & {
  type: typeof MENU_ITEM_TYPE_DIV;
};

export type TopMenuItems = (TopMenuItem | DivMenuItem)[];

export type SubMenuItems = (SubMenuItem | DivMenuItem)[];

export type MenuItemType = TopMenuItem | SubMenuItem | DivMenuItem;

export function isMenuItemChildren(item: Record<string, unknown>): item is MenuItemChildren {
  return Object.keys(item).includes('children');
}

export function isTopMenuItem<T extends { type: unknown }>(item: MenuItemType | T): item is TopMenuItem {
  return item.type === MENU_ITEM_TYPE_TOP;
}

export function isSubMenuItem<T extends { type: unknown }>(item: MenuItemType | T): item is SubMenuItem {
  return item.type === MENU_ITEM_TYPE_SUB;
}

export function isDivMenuItem<T extends { type: unknown }>(item: MenuItemType | T): item is DivMenuItem {
  return item.type === MENU_ITEM_TYPE_DIV;
}

export function find(items: (TopMenuItem | SubMenuItem | DivMenuItem)[], id: string): TopMenuItem | SubMenuItem | undefined {
  return items.reduce<TopMenuItem | SubMenuItem | undefined>((previousValue, currentValue) => {
    if (!previousValue) {
      if (currentValue.id === id) {
        if (!isDivMenuItem(currentValue)) {
          return currentValue;
        }
      }
      if (isMenuItemChildren(currentValue)) {
        const inner = find(currentValue.children, id);
        if (inner) {
          return inner;
        }
      }
    }
    return previousValue;
  }, undefined);
}

export const HomeMenu: TopMenuItem = {
  type: MENU_ITEM_TYPE_TOP,
  id: 'home',
  icon: Dashboard,
  title: 'Home',
  href: links.home
};

export const TenantLevelMenu: TopMenuItems = [{
  type: MENU_ITEM_TYPE_TOP,
  id: 'tenants',
  icon: Business,
  title: 'Tenants',
  href: links.tenants,
  permissions: [
  ]
}, {
  type: MENU_ITEM_TYPE_TOP,
  id: 'users',
  icon: Person,
  title: 'Users',
  href: links.users,
  permissions: [
  ]
}, /* {
  type: MENU_ITEM_TYPE_TOP,
  id: 'fake',
  icon: People,
  title: 'Fake',
  children: [
    {
      type: MENU_ITEM_TYPE_SUB,
      id: 'fake-1',
      title: 'Fake-1',
      children: [
        {
          type: MENU_ITEM_TYPE_SUB,
          id: 'fake-2',
          title: 'Fake-2',
          children: [
            {
              type: MENU_ITEM_TYPE_SUB,
              id: 'fake-3',
              title: 'Fake-3',
              href: links.tenant,
            }
          ]
        }
      ]
    }
  ]
},*/ ];

const Menu: TopMenuItems = [HomeMenu, ...TenantLevelMenu];

export default Menu;
