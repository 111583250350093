import { Box } from '@mui/material';
import Menu, { TopMenuItems } from './menu';
import OncoreFlexMenuDesktop from './OncoreFlexMenuDesktop';
import OncoreFlexMenuMobile from './OncoreFlexMenuMobile';

export type Props = {
  items: TopMenuItems;
};

const OncoreFlexMenu = (props: Props) => {
  const { items } = props;

  return <>
    <Box sx={{ display: { sm: 'none' } }}>
      <OncoreFlexMenuMobile items={items} />
    </Box>
    <Box sx={{ display: { xs: 'none', sm: 'block', marginTop: '30px' } }}>
      <OncoreFlexMenuDesktop items={items} />
    </Box>
  </>;
};

OncoreFlexMenu.defaultProps = {
  items: Menu
};

export default OncoreFlexMenu;
