import { ReactNode } from 'react';
import { Box, BoxProps } from '@mui/material';

export type Props = {
  icon?: ReactNode;
  height?: number | string;
  children?: any;
};

const OncoreFlexMenuContainer = (props: BoxProps) => (
  <Box 
    {...props}
    sx={(theme) => ({
      display: 'grid',
      gridTemplateColumns: '70px auto',
      gridTemplateRows: 'auto',
      color: theme.palette.primary.contrastText,
    })}
  />
);

const OncoreFlexMenuIconNode = (props: BoxProps) => (
  <Box 
    {...props}
    sx={(theme) => ({
      gridColumnStart: 1,
      backgroundColor: theme.palette.primary.dark,
      display: 'grid',
      placeItems: 'center',
      justifyContent: 'center'
    })}
  />
);

const OncoreFlexMenuChildNode = (props: BoxProps) => (
  <Box 
    {...props}
    sx={(theme) => ({
      gridColumnStart: 2,
      backgroundColor: theme.palette.primary.main,
      display: 'grid',
      alignContent: 'center',
      alignItems: 'start'
    })}
  />
);

const OncoreFlexMenuRow = (props: Props) => {
  const {
    icon,
    children,
    ...rest
  } = props;

  return (
    <OncoreFlexMenuContainer {...rest}>
      <OncoreFlexMenuIconNode>
        {icon}
      </OncoreFlexMenuIconNode>
      <OncoreFlexMenuChildNode>
        {children}
      </OncoreFlexMenuChildNode>
    </OncoreFlexMenuContainer>
  );
};

export default OncoreFlexMenuRow;
