import React from 'react';
import {
  createBrowserRouter,
  Navigate,
  RouteObject,
  RouterProvider
} from 'react-router-dom';
import { ErrorBoundary } from '@oncore/shared';
import { useSelector } from 'src/hooks';
import ScrollReset from '../ScrollReset';
import OncoreFlexLayout from '../chrome/OncoreFlexLayout';
import { links } from '../chrome';
import AuthGuard from './AuthGuard';

const Tenants = React.lazy(() => import('src/pages/Tenants'));
const AgencyNew = React.lazy(() => import('src/pages/AgencyNew'));
const EnterpriseNew = React.lazy(() => import('src/pages/EnterpriseNew'));

const TenantRoot = React.lazy(() => import('src/pages/Tenant/Tenant'));
const TenantRedirect = React.lazy(() => import('src/pages/Tenant/Redirect'));
const TenantDetails = React.lazy(() => import('src/pages/Tenant/Details'));
const TenantSettings = React.lazy(() => import('src/pages/Tenant/Settings'));
const TenantUsers = React.lazy(() => import('src/pages/Tenant/Users'));
const TenantGroups = React.lazy(() => import('src/pages/Tenant/Groups'));
const TenantPositions = React.lazy(() => import('src/pages/Tenant/Positions'));
const TenantPlacements = React.lazy(() => import('src/pages/Tenant/Placements'));
const TenantContracts = React.lazy(() => import('src/pages/Tenant/Contracts'));
const TenantTimesheets = React.lazy(() => import('src/pages/Tenant/Timesheets'));

const Users = React.lazy(() => import('src/pages/Users'));
const UserNew = React.lazy(() => import('src/pages/UserNew'));
const UserRoot = React.lazy(() => import('src/pages/User'));
const UserRedirect = React.lazy(() => import('src/pages/User/Redirect'));
const UserDetails = React.lazy(() => import('src/pages/User/Details'));
const UserInvoices = React.lazy(() => import('src/pages/User/Invoices'));

const NotFoundView = React.lazy(() => import('src/pages/NotFoundView'));

export type Props = {
  children?: never;
};

export const OncoreFlexRouter = (_: Props) => {
  const { context } = useSelector((s) => s.app);

  const catchAll = context.context === 'loading' ? [{
    path: '*',
    element: (
      <React.Suspense>
        <ErrorBoundary>
          <AuthGuard />
        </ErrorBoundary>
      </React.Suspense>
    )
  }] : [{
    path: '/',
    element: <Navigate to={links.tenants} replace />
  }, {
    path: '*',
    element: (
      <React.Suspense>
        <ErrorBoundary>
          <NotFoundView />
        </ErrorBoundary>
      </React.Suspense>
    )
  }];

  const layoutFor = (element: JSX.Element, children?: RouteObject[]): RouteObject => ({
    element: (
      <React.Suspense>
        <ErrorBoundary>
          {element}
        </ErrorBoundary>
      </React.Suspense>
    ),
    children
  });

  const childRouteFor = (path: string, element: JSX.Element, children?: RouteObject[]): RouteObject => ({
    path,
    element: (
      <React.Suspense>
        <ErrorBoundary>
          {element}
        </ErrorBoundary>
      </React.Suspense>
    ),
    children
  });

  const authRouteFor = (path: string, element: JSX.Element, children?: RouteObject[]): RouteObject => ({
    path,
    Component: AuthGuard,
    children: [{
      element: (
        <React.Suspense>
          <ScrollReset />
          <ErrorBoundary>
            <OncoreFlexLayout />
          </ErrorBoundary>
        </React.Suspense>
      ),
      children: [
        ...(children || []), {
          index: true,
          element: (
          <React.Suspense>
            <ErrorBoundary>
              {element}
            </ErrorBoundary>
          </React.Suspense>
          )
        }]
    }]
  });

  const router = createBrowserRouter([
    authRouteFor('tenants', <Tenants />),
    authRouteFor('tenants/agency/new', <AgencyNew />),
    authRouteFor('tenants/enterprise/new', <EnterpriseNew />),
    authRouteFor('tenants/:tenantID', <TenantRedirect />, [
      layoutFor(<TenantRoot />, [
        childRouteFor('details', <TenantDetails />),
        childRouteFor('settings', <TenantSettings />),
        childRouteFor('users', <TenantUsers />),
        childRouteFor('groups', <TenantGroups />),
        childRouteFor('positions', <TenantPositions />),
        childRouteFor('placements', <TenantPlacements />),
        childRouteFor('contracts', <TenantContracts />),
        childRouteFor('timesheets', <TenantTimesheets />)])
    ]),
    authRouteFor('users', <Users />),
    authRouteFor('users/new', <UserNew />),
    authRouteFor('users/:userID', <UserRedirect />, [
      layoutFor(<UserRoot />, [
        childRouteFor('details', <UserDetails />),
        childRouteFor('invoices', <UserInvoices />)
      ])
    ]),
    ...catchAll]);

  return (<RouterProvider router={router} />);
};

export default OncoreFlexRouter;
