import { LogOut } from 'react-feather';
import { useAuthLogout } from 'src/hooks';
import { MENU_ITEM_TYPE_TOP, TopMenuItem } from './menu';
import OncoreFlexMenuMobileItem from './OncoreFlexMenuMobileItem';

const OncoreFlexMenuMobileLogout = (props: any) => {

  const logout = useAuthLogout();

  const item: TopMenuItem = {
    id: 'logout',
    type: MENU_ITEM_TYPE_TOP,
    icon: LogOut,
    title: 'Logout',
    onClick: logout,
  };

  return (
    <OncoreFlexMenuMobileItem {...props} item={item} goSub={null} />
  );
};

export default OncoreFlexMenuMobileLogout;
