import {
  Environment,
  FetchFunction,
  GraphQLResponse,
  Network,
  Observable,
  RecordSource,
  Store
} from 'relay-runtime';
import { createClient } from 'graphql-ws';
import { axios } from '@oncore/shared';
import { SubscribeFunction } from 'relay-runtime/lib/network/RelayNetworkTypes';

export const fetchQuery: FetchFunction = (
  request,
  variables
) => axios
  .post<GraphQLResponse>('/graphql/bos', { query: request.text, operationName: request.name, variables })
  .then(x => x.data);

const subscribe: SubscribeFunction = (request, variables) => {
  const subscriptionClient = createClient({
    url: (axios.defaults.baseURL || '')
      .replace('http://', 'ws://')
      .replace('https://', 'wss://')
      .concat('/graphql/bos'),
    connectionParams: () => ({
      authorization: `Bearer ${localStorage.getItem('token')}`
    })
  });
  return Observable.create((sink: any) => {
    return subscriptionClient.subscribe(
      {
        operationName: request.name,
        query: request.text || '',
        variables,
      },
      sink,
    );
  });
};

const environment = new Environment({
  network: Network.create(fetchQuery, subscribe),
  store: new Store(new RecordSource()),
});

export default environment;
